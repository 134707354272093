<template>
  <img
    class="circleBlueLoading"
    v-show="loadingCircle"
    :style="{ top: `${bodyHeight / 2 - 20}px` }"
    src="../../assets/imgs/circleBlueLoading.png"
  />
</template>

<script>
export default {
  name: "loadingCircle",
  data() {
    return {
      bodyHeight: window.screen.height,
    };
  },
  props: {
    loadingCircle: {
      type: Boolean,
      default: false,
    },
    LoadingText: {
      type: String,
      default: "服务正在加载...",
      //  default: () => []
    },
  },
  created() {
    // var that = this;
    // this.bus.$on("loading", function (data) {
    //   that.loading = !!data;
    // });
  },
};
</script>

<style scoped lang="scss">
.circleBlueLoading {
  width: 40px;
  height: 40px;
  position: fixed;
  animation: rotate 0.7s linear infinite;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  z-index: 999;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
