<template>
  <section class="toast-container" :style="{ top: `${bodyHeight / 2 - 20}px` }">
    <div class="toast" v-bind:class="[visible ? 'fade-in' : 'fade-out']">
      <img
        class="toast-pic"
        alt=""
        :src="toastPic"
        v-if="toastPic !== undefined"
      />
      <span>{{ message }}</span>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.7);
  }
}

.toast-container {
  position: absolute;
  left: 0;
  // top: 0;
  // bottom: 0;
  right: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  .toast {
    padding: 8px 16px;
    max-width: 300px;
    text-align: center;

    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000;
    border-radius: 10px;
  }

  .toast-pic {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .fade-in {
    animation-name: fade-in;
    animation-duration: 0.3s;
    animation-fill-mode: both;
  }

  .fade-out {
    animation-name: fade-out;
    animation-duration: 0.3s;
    animation-fill-mode: both;
  }
}
</style>
<script>
export default {
  data() {
    return {
      visible: false,
      message: '',
      toastPic: '', // 图片路径
      bodyHeight: window.screen.height
    }
  },
}
</script>
