<template>
  <div
    class="pullRefresh"
    ref="scrollBox"
    :style="style"
    @touchstart="touchStart"
    @touchmove="touchMove"
    @touchend="touchEnd"
  >
    <!--下拉刷新的文案-->
    <div class="box">
      <div v-if="moveState < 2">
        {{ moveState === 0 ? "下拉刷新 ···" : "释放刷新 ···" }}
      </div>
      <div v-else-if="moveState === 2"><i class="loading" /> 加载中 ···</div>
      <div v-else-if="moveState === 3"><i class="loading" /> 刷新成功！ </div>
    </div>

    <!--插槽-->
    <div class="present-box">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: "PullDownRefresh",
  data() {
    return {
      startY: "", //  touch时的Y坐标
      moveDistance: 0, // 向下滑动的距离
      moveState: 0, //  开始滑动到结束后状态的变化 0-下拉刷新 1-释放刷新 2-加载中 3-刷新完成
      duration: 0, // 动画持续时间，0就是没有动画
    };
  },
  computed: {
    style() {
      return {
        transition: `all ${this.duration}ms`,
        transform: `translateY(${this.moveDistance}px)`,
      };
    },
  },
  methods: {
    touchStart(e) {
      // let scrollTop = this.$refs.scrollBox.parentNode.scrollTop;
      // console.log('scrollTop', scrollTop)
      // if (scrollTop > 0) return;
      this.duration = 0; // 关闭动画
      this.moveDistance = 0; // 滑动距离归0
      this.startY = e.targetTouches[0].clientY; // 获得开始Y坐标
      console.log("this.startY", this.startY);
    },
    touchMove(e) {
      console.log(e.targetTouches[0].clientY);
      // let scrollTop =
      //   document.documentElement.scrollTop || document.body.scrollTop;
      let scrollTop = this.$refs.scrollBox.parentNode.scrollTop;
      if (scrollTop > 0) return;
      let move = e.targetTouches[0].clientY - this.startY; //  手指滑动距离
      if (move > 0) {
        e.preventDefault();
        this.moveDistance = Math.pow(move, 0.8); //幂次方 增加滑动阻力的感觉
        if (this.moveDistance > 50) {
          //如果滑动距离大于50 释放即可刷新
          if (this.moveState === 1) return;
          this.moveState = 1;
        } else {
          //否则 恢复原样
          if (this.moveState === 0) return;
          this.moveState = 0;
        }
      }
    },
    touchEnd() {
      let duration = 500;
      this.duration = duration;
      if (this.moveDistance > 50) {
        //这里逻辑跟touchMove一样，但是需要真的加载数据了，那moveState变为2 所以加载动画在这出现
        this.moveState = 2;
        this.moveDistance = 0;
        //成功回调
        this.$emit("refresh", () => {
          this.moveState = 3;
          setTimeout(()=>this.moveState = 0, duration)
        });
      } else {
        this.moveDistance = 0;
      }
    },
  },
  watch: {
    moveState(state) {
      // if (state === 0 && this.duration > 300) {
      if (state === 0) {
        this.moveDistance = 0;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.pullRefresh {
  flex-direction: column;
  margin-top: -0.8rem;
  flex: 1;
  height: 100%;
  transition: all;
  /*position: relative;*/

  .box {
    line-height: 0.8rem;
    height: 0.8rem;
    font-size: 0.28rem;
    color: #a0a0a0;
    text-align: center;
    @include background_color("bg");
    /*position: fixed;
    top: -0.8rem;*/
  }
  .present-box {
    /*flex: 1;*/
    display: flex;
    height: 100%;
    // background-color: #fff;
  }
}
[data-theme="light"] .pullRefresh {
  height: 100%;
}
</style>
