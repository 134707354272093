import Loading from "./Loading.vue";
//先创建一个空实例
let instance = null;
export default {
  install(Vue) {
    if (!instance) {
      //构造器 /子类
      let MyLoading = Vue.extend(Loading);
      instance = new MyLoading({
        //创建一个div,并挂载上去
        el: document.createElement("div"),
      });

      document.body.appendChild(instance.$el);
    }

    instance.loadingOpendoor = false; //默认false,关闭loading

    //自定义一些方法,操作loading的显示与隐藏关
    let customMethods = {
      start(text) {
        instance.loadingOpendoor = true;
        instance.LoadingText = text;
      },
      end() {
        instance.loadingOpendoor = false;
      },
    };
    //挂载到自定义方法vue示例上
    if (!Vue.$loadingOpendoor) {
      Vue.$loadingOpendoor = customMethods;
      //挂载到原型上
      Vue.prototype.$loadingOpendoor = Vue.$loadingOpendoor;
    } else {
      console.log("$loading方法已被占用");
    }
  },
};
