<template>
  <div
    class="markbox"
    v-show="loading"
    style="background-color: rgba(0, 0, 0, 0.5)"
  >
    <div class="sun-loading"></div>
    <div class="text">{{ LoadingText }}</div>
  </div>
</template>

<script>
export default {
  name: 'loading',
  data() {
    return {};
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    LoadingText: {
      type: String,
      default: '服务正在加载...',
      //  default: () => []
    },
  },
  created() {
    // var that = this;
    // this.bus.$on("loading", function (data) {
    //   that.loading = !!data;
    // });
  },
};
</script>

<style scoped lang="scss">
.markbox {
  width: 2.56rem;
  height: 2.56rem;
  text-align: center;
  // background-color: rgba($color: #000000, $alpha: 0.5);
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .text {
    height: 0.4rem;
    line-height: 0.4rem;
    color: #ffffff;
    font-size: 0.32rem;
    margin-top: 0.4rem;
  }
  .sun-loading {
    width: 1.44rem;
    height: 0.82rem;
    display: inline-block;
    background: transparent
      url('https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/couponGroup/loading.gif');
    background-size: 100%;
  }
}
</style>
