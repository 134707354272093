<style scoped lang="scss">
  .pullRefresh{
    height: auto!important;
    @include background_color("index_bg");
    /*display: flex;*/

    .present-box{
      height: 100%;
    }
  }
  .BODY{
    /*background: #fff;*/
    width: 100%;
    flex: 1;
    position: relative;
    @include background_color("index_bg");
  }
  .head{
    height: 1.16rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 .4rem 0 .48rem;
    font-size: .48rem;
    font-weight: bold;
    @include font_color("title_color");

    &>span{
      font-size: .3rem;
      color: #AEB5CA;
    }
  }
  .list{
    margin: .6rem 6.4% 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item{
      width: 3rem;
      margin-bottom: .6rem;
      display: flex;
      flex-direction: column;

      .img{
        width: 3rem;
        height: 3rem;
        position: relative;
        border-radius: .12rem;
        overflow: hidden;

        .skuImg{
          width: 100%;
          height: 100%;
        }
        .region{
          width: .68rem;
          height: .68rem;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
        }
        .stockOut{
          width: 100%;
          height: .56rem;
          line-height: .56rem;
          text-align: center;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          background: rgba(0,0,0,.6);
          font-size: .28rem;
          color: #FFFFFF;
        }
      }
      .brand{
        display: flex;
        justify-content: space-between;
        margin-top: .28rem;
        font-size: .22rem;
        color: #000000;
        z-index: 1;

        .brandName{
          flex: 1;
          margin-right: .05rem;
          overflow:hidden; text-overflow:ellipsis; white-space:nowrap;
          @include font_color("title_color");
        }
        .skuCount{
          color: #AEB5CA;
        }
      }
      .name{
        height: .9rem;
        line-height: .45rem;
        font-size: .32rem;
        color: #000000;
        font-weight: bold;
        margin-top: .12rem;
        overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;
        /*display: flex;*/
        /*align-items: flex-end;*/
        /*flex-wrap: wrap;*/
        word-break:break-all;
        @include font_color("title_color");

        .discount{
          border: .02rem solid rgba(255,74,38,1);
          border-radius: .16rem;
          font-size: .2rem;
          color: #FB4E44;
          margin-right: .1rem;
          /*width: .7rem;*/
          height: .32rem;
          padding: 0.04rem 0.08rem;
          /*display: flex;*/
          /*justify-content: center;*/
          /*align-items: center;*/
          /*align-self: flex-start;*/
          position: relative;
          top: -.04rem;
          /*transform: translateY(-0.1rem);*/
        }
      }
      .oriPrice{
        font-size: .22rem;
        color: #A0A0A0;
        margin-top: .16rem;
        text-decoration: line-through;
      }
      .appVip {
        width: 1.8rem;
        height: .36rem;
        line-height: .4rem;
        background: #FFEFAE;
        border-radius: 0.08rem;
        overflow: hidden;
        margin-top: .14rem;
        .sprice {
          width: .76rem;
          height: .36rem;
          line-height: .4rem;
          background-image: linear-gradient(90deg, #FE6E50 0%, #FF5035 100%);
          border-radius: .08rem;
          font-size: .26rem;
          display: inline-block;
          float: left;
          color: #FFFFFF;
          font-size: .26rem;
          text-align: center;
          .sym{
            font-size: .2rem;
          }
          .small{
            font-size: .2rem;
          }
        }
        .vipText {
          width: 1.04rem;
          height: .36rem;
          line-height: .4rem;
          font-size: .22rem;
          text-align: center;
          color: #222427;
          display: inline-block;
          float: left;
        }
      }
      .operation{
        margin-top: -0.03rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-weight: bold;

        .price{
          color: #FF613E;
          font-size: .36rem;

          .sym{
            font-size: .24rem;
          }
          .small{
            font-size: .22rem;
          }
        }
        .buy{
          background: #FF613E;
          border-radius: .28rem;
          font-size: .28rem;
          color: #FFFFFF;
          padding: 0 .26rem;
          height: .56rem;
          display: flex;
          align-items: center;
          //@include title_bg;
        }
        .disabled{
          /*background: #D6D9E4;*/
          @include font_color("home_btn_txt_color_disabled");
          @include background_color("home_btn_bg_disabled");
        }
      }

      &.op{
        .brand, .name, .oriPrice.oriPrice{
          opacity: .2;
        }
        .img{
          .skuImg{
            opacity: .2;
          }
          .region{
            opacity: .3;
          }
        }
        .operation{
          .price{
            opacity: .2;
          }
          .buy{
            background: #E7EAF3;
          }
        }
      }
    }
  }

  .notSku{
    width: 60vw;
    height: 60vw;
    margin: 1.6rem 20vw 0;
  }
  .notSkuTxt{
    font-size: .36rem;
    color: #222222;
    width: 100%;
    text-align: center;
    margin-top: .3rem;
    @include font_color("card_info_color");
  }


</style>

<template>
  <!-- 下拉刷新 -->
  <PullDownRefresh @refresh="refresh">
    <div class="BODY" v-if="loading"></div>
    <!-- 空数据 -->
    <div class="BODY" v-else-if="skuList.length<=0">
      <div class="head" style="position: fixed">在售商品</div>

      <img class="notSku" src="../assets/imgs/noneGoods.png" alt="">
      <div class="notSkuTxt">暂无商品</div>
    </div>

    <!-- 有数据 -->
    <div class="BODY" v-else>
      <div class="head" ref="refHeight">在售商品 <span>无人车 {{vinId}}</span></div>
      <div class="list">
        <template v-for="item in skuList">
          <div :class="item.skuCount <= 0 ? 'item op' : 'item'" >
            <div class="img">
              <img :src="item.skuImage" :alt="item.skuName" class="skuImg">
              <img :src="require('@/assets/imgs/'+item.region.toLocaleLowerCase()+'.png')" :alt="item.region + '托盘'" class="region" v-if="item.region">
              <div class="stockOut" v-if="item.skuCount <= 0">已售罄</div>
            </div>
            <div class="brand">
              <span class="brandName">{{item.supplierName || '　'}}</span>
              <span class="skuCount">剩余{{item.skuCount}}</span>
            </div>
            <div class="name">
              <span class="discount" v-if="item.promotionRemark === 1 && item.skuPrice != 0 && item.discount > 0 && item.discount < 10">{{item.discount}}折</span>
              <span>{{item.skuName}}</span>
            </div>
            <div class="oriPrice" v-if="!item.promotionRemark" style="opacity:0">￥{{item.skuOriPrice}}</div>
            <div class="oriPrice" v-if="item.promotionRemark === 1">￥{{item.skuOriPrice}}</div>
            <div class="appVip"  v-if="item.promotionRemark == 2">
              <div class="sprice">
                <span class="sym">￥</span>
                <span>{{item.appPriceArr[0]}}</span>
                <span class="small" v-if="item.appPriceArr[1]">.{{item.appPriceArr[1]}}</span>
              </div>
              <div class="vipText">
                APP专享
              </div>
            </div> 

            <div class="operation">
              <div class="price">
                <span class="sym">￥</span>
                <span>{{item.priceArr[0]}}</span>
                <span class="small" v-if="item.priceArr[1]">.{{item.priceArr[1]}}</span>
              </div>
              <div class="buy" :class="{'disabled' : item.skuCount <= 0}" @click="createOrder(item)">购买</div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </PullDownRefresh>


</template>

<script>
import request from "@/utils/request";
import PullDownRefresh from "@/components/PullDownRefresh";


export default {
  name: "Home",
  components: {
    PullDownRefresh,
  },
  props: {},
  data() {
    return {
      skuList: [],
      arrA: [],
      arrB: [],
      arrC: [],
      arrD: [],
      vinId: "--",
      carNum: 0, //购物车中当前商品数量
      maxNum: 5, //购物车最大数量
      shoppingCar: [],
      top: "-9999rem",
      left: "-9999rem",
      opacity: 1, //购物车小球的透明度
      showBall: false, //是否显示假如购物车动画的小球
      canAddShoppingCar: true, //防止假如购物车连点
      vin: "",
      // vin: "LHT5Y2A43MC4DA001",//3101
      // vin: "LHT5Y2A4XLC4WA003",//303
      // vin: "LHT5Y2A46MC32A001",//355
      // vin: "LHT5Y2A4XMC8JA005",//3385
      // vin: "LHT4B2A18KC8KA001",//c74
      token: "",
      scrollTop: 0,
      loading:true,
      app:null,
    };
  },
  computed: {},
  created: function () {
  },
  activated() {
    // 等到DOM更新完成之后，然后，执行this.$nextTick，类似于Promise then()
    this.$nextTick(() => {
      this.app.scrollTo(0, this.scrollTop * -1);
    });
  },
  mounted: function () {
    this.app = document.getElementById('app');
    this.app.addEventListener("scroll", this.handleScroll);

    if (this.$route.query.vin) {
      this.vin = this.$route.query.vin;
      sessionStorage.setItem("vin", this.vin);
    }
    if (this.$route.query.token) {
      this.token = this.$route.query.token;
      sessionStorage.setItem("token", this.token);
    }
    // this.shoppingCar = JSON.parse(sessionStorage.getItem("shoppingCar")) || [];
    // this.carNum = JSON.parse(sessionStorage.getItem("shoppingCarNum")) || 0;
    this.getSkuList();
    this.getVinInfo();
  },
  destroyed() {
    // this.$refs.contains.removeEventListener("scroll", this.handleScroll);
    this.utils.setTitle("");
    this.app.removeEventListener('scroll', this.handleScroll, false);
  },
  methods: {
    getSkuList(done) {
      request({
        url: "/web/sku/getSkuList",
        method: "POST",
        data: {
          vin: this.vin, //303
        },
      }).then((res) => {
        done && done();
        this.loading = false;
        // return;

        this.skuList = [...res.result, ...(res.result2 || [])];
        this.skuList.map((v) => {
          v.tagList = v.description ? v.description.split(";") : [];
          v.priceArr = `${v.skuPrice}`.split('.');
          if (v.promotionRemark === 1) {
            let num = (v.skuPrice / v.skuOriPrice) * 10;
            v.discount = num >= 0.1 ? num.toFixed(1) : 0;
          }
          v.appPriceArr = `${v.appSkuPrice}`.split('.');
        });
        // if (this.skuList.length > 0) {
        //   setTimeout(()=>this.$refs.contains.addEventListener("scroll", this.handleScroll),0)
        // }
        console.log('this.skuList', this.skuList)
      }).catch(err=>{
        done && done();
        this.loading = false;
      });
    },
    getVinInfo() {
      request({
        url: "/web/vehicle/getVehicleByWin",
        method: "POST",
        data: {
          vin: this.vin, //303
        },
      }).then((res) => {
        // console.log("车辆详情", res);
        this.vinId = res.vinId;
        sessionStorage.setItem("vinInfo", JSON.stringify(res));
      });
    },
    addShoppingCar(item, e) {
      console.log(item);
      if (!this.canAddShoppingCar) return false;
      this.canAddShoppingCar = false;

      let arr = this.shoppingCar; //购物车里的商品
      let totalNum = 0; //购物车里的商品总数
      let openSkuNum = item.skuCount; //当前商品可用库存
      let skuNum = 0; //当前商品在购物车里的数量

      //算出购物车里的商品总数和当前商品数
      arr.map((v) => {
        if (v.number) {
          v.check && (totalNum = totalNum + v.number);
          v.skuCode === item.skuCode &&
          v.skuPrice === item.skuPrice &&
          v.region === item.region
            ? (skuNum = v.number)
            : "";
        }
      });

      //购物车当前容量超过购物车最大容量
      if (totalNum >= this.maxNum) {
        alert("购物车已满");
        return false;
      }

      //当前商品可用库存等于当前商品在购物车里的数量
      if (openSkuNum === skuNum) {
        alert("当前商品库存不足");
        return false;
      }

      //购物车里有商品
      if (arr.length > 0) {
        // 只能买同一侧柜门的限制
        // if (arr[0].sideNo !== item.sideNo) {
        //   alert('每次只能购买同一侧柜门的商品哦~');
        //   return;
        // }
        for (let i = 0; i < arr.length; i++) {
          //相同的商品
          if (
            arr[i].skuCode === item.skuCode &&
            arr[i].skuPrice === item.skuPrice &&
            arr[i].region === item.region
          ) {
            if (arr[i].check) {
              arr[i].number = arr[i].number + 1;
              this.setShoppingCarNum(item, e);
            } else {
              alert("购物车中已有此商品");
            }
            break;
          }
          //不同的商品
          else {
            if (i + 1 === arr.length) {
              //遍历的最后一个商品
              arr.push({
                skuCode: item.skuCode,
                skuCount: item.skuCount,
                number: 1,
                skuPrice: item.skuPrice,
                skuImage: item.skuImage,
                skuName: item.skuName,
                sideNo: item.sideNo,
                region: item.region,
                supplierName: item.supplierName,
                supplierUrl: item.supplierUrl,
                check: true,
                testFlag: item.testFlag,
              });
              this.setShoppingCarNum(item, e);
              break;
            }
          }
        }
      }
      //购物车里没商品
      else {
        arr.push({
          skuCode: item.skuCode,
          skuCount: item.skuCount,
          number: 1,
          skuPrice: item.skuPrice,
          skuImage: item.skuImage,
          skuName: item.skuName,
          sideNo: item.sideNo,
          region: item.region,
          supplierName: item.supplierName,
          supplierUrl: item.supplierUrl,
          check: true,
          testFlag: item.testFlag,
        });
        this.setShoppingCarNum(item, e);
      }
      this.shoppingCar = arr;
      this.carNum = totalNum + 1;
      sessionStorage.setItem("shoppingCar", JSON.stringify(this.shoppingCar));
      sessionStorage.setItem("shoppingCarNum", JSON.stringify(this.carNum));
    },
    setShoppingCarNum(item, e) {
      let left = (e.x || e.pageX) + "px";
      let top = (e.y || e.pageY) + "px";

      this.left = left;
      this.top = top;
      this.opacity = 1;
      this.showBall = true;

      setTimeout(() => {
        this.left = `calc(90%)`;
        this.top = `calc(72% + 0.58rem)`;
        this.opacity = 0;

        setTimeout(() => {
          this.setShoppingCarNum(item, e);
          this.showBall = false;
          this.canAddShoppingCar = true;
        }, 500);
      }, 20);
    },
    toShoppingCar() {
      if (this.carNum > 0) {
        this.$router.push({
          path: "ShoppingCar",
          // query: {
          //     id: '123'
          // }
        });
      } else {
        alert("购物是空的哦");
      }
    },
    createOrder(item) {
      if (item.skuCount <= 0) return false;

      let skuListStr = `${item.skuCode},1,${item.skuPrice},${item.region}`;
      request({
        url: "/web/order/createOrder",
        // url: "aquaSku/getSkuList",
        method: "POST",
        data: {
          vin: this.vin, //303
          orderType: 19, //震雷说让写死19
          skuListStr,
        },
      }).then((res) => {
        // console.log(res)
        console.log(res.result.orderCode);
        this.$router.push({
          path: "PreOrder",
          query: {
            orderCode: res.result.orderCode,
            origin: "1",
            canBck:true,
          },
        });
      });
    },
    refresh(done){
      this.completed = false;
      this.pullDownLoading = true;
      this.getSkuList(done);
    },
    handleScroll() {
      let scrollTop = this.$refs.refHeight.getBoundingClientRect().top;
      let oneHeight = this.$refs.refHeight.offsetHeight;
      if (-oneHeight >= scrollTop) {
        this.utils.setTitle("在售商品");
        console.log('setTitle 在售商品')
      } else {
        this.utils.setTitle("");
        console.log('setTitle 空')
      }
    },
    loadEnd(){
    },
  },
};
</script>
