import ToastComponents from "./toast.vue";
const Toast = {};
Toast.install = function (Vue, options) {
  var opt = {
    duration: 2000,
  };
  for (var key in options) {
    opt[key] = options[key];
  }

  Vue.prototype.$toast = function (message, option, toastPic) {
    if (typeof option == "object") {
      for (var key in option) {
        opt[key] = option[key];
      }
    }

    const ToastController = Vue.extend(ToastComponents);

    var instance = new ToastController().$mount(document.createElement("div"));

    instance.message = message;
    instance.visible = true;
    instance.toastPic = toastPic;
    document.body.appendChild(instance.$el);

    setTimeout(() => {
      instance.visible = false;
      document.body.removeChild(instance.$el);
    }, opt.duration);
  };
  Vue.prototype.$toast["show"] = function (message, option, toastPic) {
    Vue.prototype.$toast(message, option, toastPic);
  };
};

export default Toast;
