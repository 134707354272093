const configs = {
  // 开发环境
  development: {
    baseURL: 'https://aqua.test.neolix.net/',
    baseURL2: 'https://scapi.test.neolix.net/',
    baseURL3: 'https://ndp.data.neolix.cn/service/test/ndp/aicar/',
    API_HighPrecisionLayer:
      'https://ndp.data.neolix.cn/service/dev/ndp/aiview/out/hdmap/tile',
    baseURL4: 'https://sdatacenter.test.neolix.net/',
    NDPURL: 'https://ndp.data.neolix.cn/service/dev/ndp/',

  },
  // 测试环境
  test: {
    baseURL: 'https://aqua.test.neolix.net/',
    baseURL2: 'https://scapi.test.neolix.net/',
    baseURL3: 'https://ndp.data.neolix.cn/service/test/ndp/aicar/',
    API_HighPrecisionLayer:
      'https://ndp.data.neolix.cn/service/dev/ndp/aiview/out/hdmap/tile',
    baseURL4: 'https://sdatacenter.test.neolix.net/',
    NDPURL: 'https://ndp.data.neolix.cn/service/dev/ndp/',
  },
  // 预发环境
  uat: {
    baseURL: 'https://aqua.uat.neolix.net/',
    baseURL2: 'https://scapi.neolix.net/',
    baseURL3: 'https://ndp.data.neolix.cn/service/pro/ndp/aicar/',
    API_HighPrecisionLayer:
      'https://ndp.data.neolix.cn/service/pro/ndp/aiview/out/hdmap/tile',
    baseURL4: 'https://datacenter.neolix.net/',
    NDPURL: 'https://ndp.data.neolix.cn/service/pro/ndp/',
  },

  // 生产环境
  production: {
    baseURL: 'https://aqua.cybertron.neolix.net/',
    baseURL2: 'https://scapi.neolix.net/',
    baseURL3: 'https://ndp.data.neolix.cn/service/pro/ndp/aicar/',
    API_HighPrecisionLayer:
      'https://ndp.data.neolix.cn/service/pro/ndp/aiview/out/hdmap/tile',
    baseURL4: 'https://datacenter.neolix.net/',
    NDPURL: 'https://ndp.data.neolix.cn/service/pro/ndp/',
  },
};
const DEPLOYMENT_ENV = process.env.VUE_APP_DEPLOYMENT_ENV
  ? process.env.VUE_APP_DEPLOYMENT_ENV
  : 'development';

const env = configs[DEPLOYMENT_ENV];
export default env;
