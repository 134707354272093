export default {
  state: {
    isShowLabel: false, // 是否显示POI的名字
    isShowHighPrecision: false, // 是否显示高精图层
  },
  getters: {
    getisShowLabel: (state) => state.isShowLabel,
    getisShowHighPrecision: (state) => state.isShowHighPrecision,
  },
  mutations: {
    updateisShowLabel(state, payload) {
      state.isShowLabel = payload;
    },
    updateisShowHighPrecision(state, payload) {
      state.isShowHighPrecision = payload;
    },
  },
  actions: {},
};
