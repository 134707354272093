<template>
  <div
    class="markbox"
    v-show="loadingOpendoor"
  >
    <img class="door" src="../../assets/imgs/door.png" />
    <img class="circleLoading" src="../../assets/imgs/circleLoading.png" />
    <div class="text">{{ LoadingText }}</div>
  </div>
</template>

<script>
export default {
  name: "loadingOpendoor",
  data() {
    return {};
  },
  props: {
    loadingOpendoor: {
      type: Boolean,
      default: false,
    },
    LoadingText: {
      type: String,
      default: "服务正在加载...",
      //  default: () => []
    },
  },
  created() {
    // var that = this;
    // this.bus.$on("loading", function (data) {
    //   that.loading = !!data;
    // });
  },
};
</script>

<style scoped lang="scss">
.markbox {
  width: 3.3rem;
  height: 2.4rem;
  text-align: center;
  background-color: rgba(17,17,17,0.80);
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0.2rem;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;

  .text {
    height: 0.4rem;
    line-height: 0.4rem;
    color: #ffffff;
    font-size: 0.32rem;
    font-weight: 600;
    margin-top: 0.2rem;
  }
  .door {
    width: .56rem;
    height: .56rem;
    margin-top: 0.62rem;
  }
  .circleLoading {
    width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 0.3rem;
    margin: auto;
    animation: rotate 1s linear infinite;
  }
  @keyframes rotate{
    to{
        transform: rotate(360deg);
    }
  }
}
</style>
